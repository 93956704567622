import { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { BuilderQueryContext } from "../context/BuilderQueryContext.js";
// import ImageViewListInput from "../Builder/ImageViewListInput.js";
import ImageViewInput from "./ImageViewInput.js";
import CardSearch from "./CardSearch.js";
import { AuthContext } from "../context/AuthContext.js";
import ErrorPage from "../display/ErrorPage.js";
import PopOver from "../display/PopOver.js";


function SearchGroupEdit({
    booster_sets
}) {
    const { account } = useContext(AuthContext)
    const { group_id } = useParams()

    const {
        query,
        sortState,
        boosterSet,
        rarity,
        listView,
        showMore,
        setShowMore
    } = useContext(BuilderQueryContext)

    const [searchGroup, setSearchGroup] = useState({
        name: "",
        groupCategory: "",
        members: []
    });

    const [searchGroupRef, setSearchGroupRef] = useState("")
    const [noSearchGroup, setNoSearchGroup] = useState(false)

    const [members_list, setMembersList] = useState([]);

    const [cards, setCards] = useState([]);

    const [showPool, setShowPool] = useState(true);
    const [showMembers, setShowMembers] = useState(true);

    const [noCards, setNoCards] = useState(false);

    const getSearchGroup = async() =>{
        // const groupResponse = await fetch(`http://localhost:4000/search_groups/${group_id}`)
        const groupResponse = await fetch(`https://pm-deck-react-only.onrender.com/search_groups/${group_id}`)
        if (groupResponse.ok) {
            const groupData = await groupResponse.json()
            setSearchGroup(groupData)
            setSearchGroupRef(groupData)

            const cardsResponse = await fetch("https://pm-deck-react-only.onrender.com/cards")
            // const cardsResponse = await fetch("http://localhost:4000/cards/")
            const cardsData = await cardsResponse.json()

            const sortedCards = [...cardsData].sort((a,b) => a.name.localeCompare(b.name));
            const membersList = sortedCards.filter(card => groupData.members.includes(card.card_number))
            console.log(membersList)
            setMembersList(membersList)
        } else {
            setNoSearchGroup(true)
        }
    };

    const getCards = async() =>{
        const response = await fetch("https://pm-deck-react-only.onrender.com/cards")
        const data = await response.json();
        console.log(data)
        if (data.length == 0 ) {
            setNoCards(true)
        }
        const sortedCards = [...data].sort(sortMethods[sortState].method);
        setCards(sortedCards);
    };

    useEffect(() => {
        window.scroll(0, 0);
        document.body.style.overflow = 'auto';
        getSearchGroup();
        getCards();
        document.title = "Search Group Edit - PM CardBase"
        return () => {
            document.title = "PlayMaker CardBase"
        };
    // eslint-disable-next-line
    },[]);

    const sortMethods = {
        none: { method: (a,b) => a.card_number - b.card_number },
        newest: { method: (a,b) => b.id.localeCompare(a.id) },
        oldest: { method: (a,b) => a.id.localeCompare(b.id) },
        name: { method: (a,b) => a.name.localeCompare(b.name) },
        card_number: { method: (a,b) => a.card_number - b.card_number },
        enthusiasm_highest: { method: (a,b) => b.enthusiasm - a.enthusiasm },
        enthusiasm_lowest: { method: (a,b) => a.enthusiasm - b.enthusiasm },
    };

    const all_cards = cards.filter(card => card.name.toLowerCase().includes(query.cardName.toLowerCase()))
        .filter(card => (card.effect_text + card.second_effect_text).toLowerCase().includes(query.cardText.toLowerCase()))
        .filter(card => card.card_number.toString().includes(query.cardNumber))
        .filter(card => card.hero_id.toLowerCase().includes(query.heroID.toLowerCase()))
        .filter(card => card.series_name.toLowerCase().includes(query.series.toLowerCase()))
        .filter(card => card.card_number >= query.startingNum)
        .filter(card => query.type? card.card_type.some(type => type.toString() == query.type):card.card_type)
        .filter(card => card.card_class.includes(query.cardClass))
        .filter(card => query.extraEffect? card.extra_effects.some(effect => effect.toString() == query.extraEffect):card.extra_effects)
        .filter(card => query.reaction? card.reactions.some(reaction => reaction.toString() == query.reaction):card.reactions)
        .filter(card => query.tag? card.card_tags.some(tag => tag.toString() == query.tag):card.card_tags)
        .filter(card => boosterSet && !rarity ? boosterSet.all_cards.includes(card.card_number):card.card_number)
        .filter(card => boosterSet && rarity ? boosterSet[rarity].includes(card.card_number):card.card_number)
        .sort(sortMethods[sortState].method)

    const handleShowMore = (event) => {
        setShowMore(showMore + 20)
    };

    const handleChange = (event) => {
        setSearchGroup({ ...searchGroup, [event.target.name]: event.target.value });
    };

    const handleClick = (card) => {
        console.log(members_list.filter(member => member.card_number === card.card_number))
        if (members_list.filter(member => member.card_number === card.card_number).length === 0) {
            setMembersList([...members_list, card])
        }
    }

    const handleRemoveCard = (card) => {
        const membersIndex = members_list.indexOf(card);
        const newMembersList = [...members_list];
        newMembersList.splice(membersIndex, 1);
        setMembersList(newMembersList);
    }

    const clearMembers = () => {
        setSearchGroup({
            name: "",
            groupCategory: "",
            members: []
        })
        setMembersList([])
    }

    const navigate = useNavigate()
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = {...searchGroup};
        const update = {}
        const members = []
        for (let card of members_list){
            if (!members.includes(card.card_number)) {
                members.push(card.card_number)
            }
        }
        data["members"] = members;
        for (let key in data) {
            if (data[key] !== searchGroupRef[key]) {
                update[key] = data[key]
            }
        }
        console.log(update)
        const groupUrl = `https://pm-deck-react-only.onrender.com/search_groups/${group_id}`;
        // const groupUrl = "http://localhost:4000/search_groups/";
        const fetchConfig = {
            method: "PATCH",
            body: JSON.stringify(update),
            headers: {
                "Content-Type": "application/json",
            },
        };
        if (data["groupCategory"] !== "") {
            const response = await fetch(groupUrl, fetchConfig);
            if (response.ok) {
                const responseData = await response.json();
                // const group_id = responseData._id.toString();
                setSearchGroup({
                    name: "",
                    groupCategory: "",
                    members: []
                })
                setMembersList([])
                navigate(`/groups/${group_id}`)
                console.log("Success", responseData)
            } else {
                alert("Error in editing group");
            }
        }
    }

    const handleShowPool = (event) => {
        setShowPool(!showPool);
    };

    const handleShowMembers = (event) => {
        setShowMembers(!showMembers);
    };

    const preprocessText = (text) => {
        return text.split("//").join("\n");
    };

    const isQueryEmpty = Object.values(query).every((value) => value === "");

    if (!(account && account.roles.includes("admin"))) {
        setTimeout(function() {
            window.location.href = `${process.env.PUBLIC_URL}/`
        }, 3000);
    }

    const [hoveredCard, setHoveredCard] = useState(null)

    return (
        <>
            { !noSearchGroup?
                <div>
                    { account && account.roles.includes("admin")?
                        <div className="white-space">
                            <PopOver
                                content={hoveredCard}
                                setObject={setHoveredCard}
                            />
                            <h1 className="margin-top-40">Search Group Edit</h1>
                            <div className="space-around media-display">
                            <div className="flex-column-items">
                                <div
                                    id="create-searchGroup-page">
                                    <h2 className="left">Group Details</h2>
                                    <h5 className="label">Name </h5>
                                    <input
                                        className="builder-input"
                                        type="text"
                                        placeholder=" Name"
                                        onChange={handleChange}
                                        name="name"
                                        value={searchGroup.name}>
                                    </input>
                                    <br/>
                                    <h5 className="label">Category </h5>
                                    <select
                                        className="builder-input"
                                        type="text"
                                        placeholder=" Category"
                                        value={searchGroup.groupCategory}
                                        name="groupCategory"
                                        onChange={handleChange}>
                                        <option value="">Category </option>
                                        <option value="damage">Damage</option>
                                        <option value="cardGain">Card Gain</option>
                                        <option value="playerBuff">Player/Card Buff</option>
                                        <option value="playerDebuff">Player/Card Debuff</option>
                                        <option value="target">Target</option>
                                        <option value="cardChange">Card Change</option>
                                        <option value="deckChange">Deck Change</option>
                                        <option value="boardChange">Play Area Change</option>
                                        <option value="playerAction">Player Action</option>
                                        <option value="activationTiming">Activation Timing</option>
                                        <option value="resolution">Resolution Effect</option>
                                        <option value="effectType">Effect Type</option>
                                        <option value="cost">Cost</option>
                                    </select>
                                    <br/>
                                    <button
                                        className="left red"
                                        onClick={handleSubmit}
                                        disabled={(
                                            searchGroup["name"] !== ""
                                            && searchGroup["groupCategory"] !== "")?
                                                false: true
                                        }
                                    >
                                        Save Group
                                    </button>
                                    <button
                                        className="left"
                                        onClick={clearMembers}
                                    >
                                        Clear List
                                    </button>
                                    <br/>

                                </div>
                            </div>
                                <div className="flex-column-full">
                                    <CardSearch
                                        boosterSets={booster_sets}
                                    />
                                </div>
                            </div>
                            <div className={showPool ? "rarities2" : "no-rarities"}>
                                <div style={{marginLeft: "0px"}}>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <h2
                                            className="left"
                                            style={{margin: "1% 0px 1% 20px", fontWeight: "700"}}
                                        >Card Pool</h2>
                                        <img className="logo" src="https://i.imgur.com/YpdBflG.png" alt="cards icon"/>
                                        {all_cards.length > 0 ?
                                            <h5
                                                className="left db-pool-count"
                                            >{all_cards.length}</h5>:
                                            null}
                                        { showPool ?
                                            <h5 className="left db-pool-count"
                                                onClick={() => handleShowPool()}>
                                                    &nbsp;[Hide]
                                            </h5> :
                                            <h5 className="left db-pool-count"
                                                onClick={() => handleShowPool()}>
                                                &nbsp;[Show]
                                            </h5>}
                                    </div>
                                    <div className={showPool ? "scrollable" : "hidden2"}>
                                        <div className="cardPoolColumn" style={{margin: "8px"}}>

                                        { all_cards.length == 0 && isQueryEmpty && !noCards?
                                            <div className="loading-container">
                                                <div className="loading-spinner"></div>
                                            </div> :
                                        null}

                                        <div className="card-pool-fill">
                                            {all_cards.slice(0, showMore).map((card) => {
                                                return (
                                                    <div style={{display: "flex", justifyContent: "center"}}>
                                                        <img
                                                            onClick={() => handleClick(card)}
                                                            className={members_list.filter(member => member.card_number === card.card_number).length > 0 ?
                                                                "builder-card glow3 greyScale" :
                                                                "builder-card pointer glow3"}
                                                            // title={`${card.name}\n${preprocessText(card.effect_text)}\n${card.second_effect_text ? preprocessText(card.second_effect_text) : ""}`}
                                                            src={card.picture_url ? card.picture_url : "https://i.imgur.com/krY25iI.png"}
                                                            onMouseEnter={() => setHoveredCard(card)}
                                                            onMouseLeave={() => setHoveredCard(null)}
                                                            alt={card.name}/>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {showMore < all_cards.length ?
                                            <div className="flex-content">
                                                <button
                                                    style={{ width: "100%", margin:".5% 4px .5% 4px"}}
                                                    onClick={handleShowMore}>
                                                    Show More Cards ({all_cards.length - showMore} Remaining)
                                                </button>
                                            </div> : null
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                listView?
                                <div className="deck-list">
                                    <div className="members">
                                    <div style={{marginLeft: "20px"}}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <h2
                                                className="left"
                                                style={{margin: "2% 0% 1% 0%", fontWeight: "700"}}
                                            >Members</h2>
                                            <img className="logo" src="https://i.imgur.com/YpdBflG.png" alt="cards icon"/>
                                            {members_list.length > 0 ?
                                            <h5
                                                className="left"
                                                style={{margin: "1% 0%", fontWeight: "700"}}
                                            >{members_list.length}</h5>:
                                            null}
                                        </div>
                                        {members_list.length > 0 ?
                                            <>
                                                {members_list.sort((a,b) => a.card_number - b.card_number).map((card) => {
                                                    return (
                                                        <div style={{padding: "5px"}}>
                                                            <div className="card-container pointer">
                                                                <h5 onClick={() => handleRemoveCard(card)}>{card.name}</h5>
                                                                <img
                                                                    className="card-image"
                                                                    src={card.picture_url}
                                                                    alt={card.name}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </>:
                                        <h4 className="left no-cards">No cards added</h4>}
                                    </div>
                                </div>
                                </div>
                            :

                                <ImageViewInput
                                    title={"Search Group"}
                                    cardList={members_list}
                                    show={showMembers}
                                    handleShowList={handleShowMembers}
                                    handleRemoveCard={handleRemoveCard}
                                />

                            }
                        </div>:
                        <div className="textwindow">
                            <h1 className="undercontext">This Feature Is For Admins Only</h1>
                            <h3 className="undercontext">Redirecting in 3 Seconds</h3>
                        </div>
                    }
                </div>:
                <ErrorPage path={"/groups/"}/>
            }
        </>
    );
}

export default SearchGroupEdit;
